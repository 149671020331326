import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Wrapper } from '../../../../components'

const WrapperSticky = styled((props) => <Wrapper {...props} />)`
background-color: #fff;
  position: fixed;
  z-index: 1000;
  height: 81px;
  top: -100px;
  transition: top .5s ease-out;
  ${(props) => props.scrolled && css`
    top: 0;
  `};

`

const PButton = styled.p`
  border: none;
  font-size: 18px;
  cursor: pointer;
  margin: 0 20px;
  width: 100px;
  font-family: ${(props) => props.theme.font.gorditaM};
  color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.secondary)};
  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`

const StickyMenu = ({
  scrolled,
  scrolledTo,
  cargoIsActive,
  passangerIsActive,
}) => {
  const { t } = useTranslation()
  return ( 
    <WrapperSticky scrolled={scrolled}>
      <Wrapper
        justifyContentD="center"
        alignItemsD="center"
      >
        <PButton
          active={cargoIsActive}
          onClick={() => scrolledTo('#cargo')}
        >
          {t('server.buttons.cargo')}
        </PButton>

        <PButton
          active={passangerIsActive}
          onClick={() => scrolledTo('#passenger')}
        >
          {t('server.buttons.passenger')}
        </PButton>
      </Wrapper>
    </WrapperSticky>

  )
}

export default StickyMenu
